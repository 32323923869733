import { BASE_URL } from ".";

export const DOCTOR_URLS = {
  addToken: `${BASE_URL}/doctor/patient/add`,
  mobileCheck: `${BASE_URL}/doctor/patient/mobile-check`,
  lastToken: `${BASE_URL}/doctor/patient/last-token`,
  listShift: `${BASE_URL}/doctor/listShift`,
};

export const PATIENT_URLS = {
  addToken: `${BASE_URL}/online-booking`,
  mobileCheck: `${BASE_URL}/mobile-check`,
  lastToken: `${BASE_URL}/last-token`,
  listShift: `${BASE_URL}/listShift`,
};

