import axios from "axios";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { AUTH_TOKEN, USER_DETAILS, BASE_URL } from "../../../utils";
import Swal from "sweetalert2";

export const AutoGeneateToken = ({
  showModal,
  autoGeneate,
  shifts,
  setShowModal,
  date,
  today,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    date: today,
    number: 0,
    shift_id:
      localStorage.getItem("shift_id") ??
      (USER_DETAILS && USER_DETAILS.active_shift_id),
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    axios
      .post(`${BASE_URL}/autotoken/generate`, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AUTH_TOKEN}`,
        },
      })
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "success",
          text: response.data.message,
        });
        setIsLoading(false);
        setShowModal(!showModal);
      })
      .catch((error) => {
        console.error("Error adding token", error.response.data.message);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error?.response?.data?.message,
        });
        setIsLoading(false);
      });
  };

  return (
    <div>
      <Modal show={showModal} onHide={autoGeneate}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="small">Auto Token Geneate</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="col-md-12 mb-3">
              <label className="mb-2">Date</label>
              <input
                className="form-control"
                onChange={(e) => handleInputChange(e)}
                name="date"
                type="date"
                value={formData.date}
                placeholder="select date"
                min={today}
                pattern="dd-mm-yyyy"
              />
            </div>
            <div className="col-md-12 mb-3">
              <label className="mb-2">
                Token length <span className="text-danger fs-5">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Token length"
                name="number"
                value={formData.number}
                maxLength={2}
                minLength={1}
                onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                onChange={(e) => handleInputChange(e)}
                required
              />
            </div>
            {shifts.length > 1 && (
              <div className="col-md-6 mb-3">
                <label className="mb-2">
                  Shift Time <span className="text-danger fs-5">*</span>
                </label>
                <select
                  className="form-control"
                  placeholder="Enter Name"
                  name="shift_id"
                  required
                  value={formData.shift_id}
                  onChange={(e) => handleInputChange(e)}
                >
                  <option value="">select shift</option>
                  {shifts.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
                </select>
              </div>
            )}
            <div className="my-3 text-end">
              <button
                disabled={isLoading}
                className="btn btn-primary ms-2"
                type="submit"
              >
                {isLoading && (
                  <span className="spinner-border spinner-border-sm mr-1"></span>
                )}
                Submit
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
