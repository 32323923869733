import axios from "axios";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { AUTH_TOKEN, BASE_URL } from "../utils";

export const PrintModel = ({ show, modelToggle, patient }) => {
  const [isLoading, setIsLoading] = useState(false);
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post( `${BASE_URL}/doctor/patient/print-slip`,
        {
          patient_id: patient.id
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );
      setIsLoading(false);
      modelToggle();
      window.open(response.data, "_blank");
    } catch (error) {
      console.error("Error fetching the API:", error);
    }
  };

  return (
    <Modal show={show}>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <h5 className="">You want to print Slip</h5>
          <div className="mt-3 text-end">
            <button
              disabled={isLoading}
              className="btn btn-primary me-2"
              type="submit"
            >
              {isLoading && (
                <span className="spinner-border spinner-border-sm mr-1"></span>
              )}{" "}
              Yes
            </button>
            <Button variant="secondary" onClick={modelToggle}>
              No
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};
