import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";
import { AUTH_TOKEN, BASE_URL } from "../../../utils";
import Swal from "sweetalert2";
import renewal from "../../../image/renewal.png";

function SubscriptionModel({ show, modelToggle, subscriptionFees }) {
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    axios
      .post(
        `${BASE_URL}/doctor/subscription`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      )
      .then((response) => {
        setIsLoading(false);
        //console.log(response.data);
        if (response.data && response.data.data) {
          window.location.href = response.data.data;
        }
        return 0;
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
      });
  };

  return (
    <Modal show={show} onHide={modelToggle}>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <div className="row mb-4">
            <div className="col-12 text-center">
              <img src={renewal} className="w-25 mb-2" />
              <h4>Subscription Renewal Amount</h4>
              <h2 className="h1">₹{subscriptionFees}</h2>
            </div>
          </div>
          <div className="text-center">
            <button
              disabled={isLoading}
              className="btn btn-primary me-2"
              type="submit"
            >
              {isLoading && (
                <span className="spinner-border spinner-border-sm mr-1"></span>
              )}{" "}
              ₹{subscriptionFees} Pay 
            </button>
            <Button className="px-4" variant="secondary" onClick={modelToggle}>
              Close
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default SubscriptionModel;
