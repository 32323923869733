import React, { useEffect, useState } from "react";
import Header from "./Header";
import { Dashboard } from "./Dashboard";
import Head from "./components/Head";
import { Col } from "react-bootstrap";
import { Input } from "antd";
import axios from "axios";
import { AUTH_TOKEN, BASE_URL, USER_DETAILS } from "../../utils";
import Utils from "../../utils/Utils";
import { BiSearch } from "react-icons/bi";

const PatientList = () => {
  const [list, setList] = useState([]);
  const [allList, setAllList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const onSearch = (e) => {
    //console.log(e.currentTarget.value);
    const value = e.currentTarget.value;
    const searchArray = e.currentTarget.value ? allList : allList;
    const data = Utils.wildCardSearch(searchArray, value);
    setList(data);
  };

  const fatchList = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${BASE_URL}/doctor/patient/list`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );

      if (response.status === 200) {
        setList(response.data.data);
        setAllList(response.data.data);
        setIsLoading(false);
      } else {
        console.error("API request failed");
      }
    } catch (error) {
      console.error("Error fetching the API:", error);
    }
  };

  useEffect(() => {
    fatchList();
  }, []);
  return (
    <div className="dashboard">
      <div
        className="d-md-none"
        style={{
          width: "100%",
          backgroundColor: "black",
          color: "wheat",
          marginBottom: "60px",
        }}
      >
        <Header />
      </div>
      <div className="d-flex">
        <Dashboard />
        <div className="w-100">
          <div className="main-container">
            <hr className="d-block d-lg-none" />
            <Head />
            <div className="reportContainer my-3 ps-3 pe-4 pe-2">
              <div className="">
                <div className="my-4">
                  <div className="d-flex flex-wrap justify-content-between">
                    <h4>Patient List</h4>
                    <Col className="text-end mb-2" xs={24} sm={24} md={6}>
                      <Input
                        placeholder="Search"
                        prefix={<BiSearch />}
                        onChange={(e) => onSearch(e)}
                      />
                    </Col>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-bordered mt-3 w-100">
                      <thead>
                        <tr>
                          <th>S.N.</th>
                          <th>Name</th>
                          <th>Mobile</th>
                          <th>Gender</th>
                          <th>Age</th>
                          <th>Address</th>
                          <th>Count</th>
                        </tr>
                      </thead>
                      <tbody>
                        {list.map((item, index) => (
                          <tr key={item.id}>
                            <td>{index + 1}</td>
                            <td>{item.name ?? ""}</td>
                            <td>{item.mobile ?? "NO"}</td>
                            <td>{item.gender ?? ""}</td>
                            <td>{item.dob ?? ""}</td>
                            <td>{item.email ?? ""}</td>
                            <td>{item.total ?? ""}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientList;
