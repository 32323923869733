import React from "react";
import { Card } from "antd";
import Chart from "react-apexcharts";
const ChartData = ({ itemData, title, isLoading }) => {
  const barChartData = {
    series: [
      {
        name: "Online Patients",
        data: itemData.online || [],
      },
      {
        name: "Offline Patients",
        data: itemData.offline || [],
      },
      {
        name: "Replace Patients",
        data: itemData.replace || [],
      },
    ],
    options: {
      colors: ["#cbaa33", "#309107", "red"],
      chart: { type: "bar", height: 250, toolbar: { show: false } },
      plotOptions: { bar: { horizontal: false, columnWidth: "55%" } },
      dataLabels: { enabled: true },
      title: { text: title, align: "left" },
      xaxis: {
        categories: itemData.days || [],
      },
    },
  };
  return (
    <Card
      style={{
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
      }}
      bodyStyle={{ padding: "15px 0px 0px 0px" }}
      loading={isLoading}
    >
      <Chart
        options={barChartData.options}
        series={barChartData.series}
        type="bar"
        height={300}
      />
    </Card>
  );
};

export default ChartData;
