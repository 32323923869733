import React from "react";
import { Token } from "../Token";

const SkipPatientDash = ({
  isLoading,
  permissions,
  skipPatients,
  skiprecallModelToggle,
  tokenInModelToggle,
  editToken,
}) => {
  return (
    <div className="card mb-4">
      <div className="card-header" style={{ backgroundColor: "#96C8A2" }}>
        <h5 className="fw-bold text-center">Skip Patients Lists</h5>
      </div>
      <div className="card-body" style={{ backgroundColor: "#E0EEEE" }}>
        <div className="">
          {isLoading ? (
            <p>Loading...</p>
          ) : (
            permissions.skipPatient > 0 && (
              <div className="ps-2 d-flex flex-wrap">
                {skipPatients.map((patient, index) => (
                  <Token
                    key={index}
                    patient={patient}
                    recallModelToggle={skiprecallModelToggle}
                    editToken={editToken}
                    tokenInModelToggle={tokenInModelToggle}
                    type="skip"
                  />
                ))}
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default SkipPatientDash;
