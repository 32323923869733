import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";

export const StartModel = ({
  show,
  isStartButtonVisible,
  handleSubmit,
  modelToggle,
  isLoading,
  formData,
}) => {
  return (
    <Modal show={show}>
      <Modal.Body>
        <p className="mb-2 my-4">
          Please type
          <i className="h6">{isStartButtonVisible ? " stop " : " start "}</i>
          for {isStartButtonVisible ? " stop " : " start "}
          consultation.
        </p>
        <form onSubmit={handleSubmit}>
          <Form.Control
            type="text"
            size="sm"
            name="text_check"
            placeholder={`Type ${isStartButtonVisible ? "stop" : "start"} for consultation`}
            required
          />
          <span className="text-danger">{formData.checkvaluemsg}</span>
          <div className="mt-4 text-end">
            <Button variant="secondary" onClick={modelToggle}>
              No
            </Button>
            <button
              disabled={isLoading}
              className="btn btn-primary ms-2"
              type="submit"
            >
              {isLoading && (
                <span className="spinner-border spinner-border-sm mr-1"></span>
              )}{" "}
              Yes
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};
