import React from "react";
import { Card } from "antd";
import ReactApexChart from "react-apexcharts";
const ChartData2 = ({ itemData, title, isLoading }) => {
  const barChartData = {
    series: [
      {
        name: "Online Patients",
        data: itemData.online || [],
      },
      {
        name: "Offline Patients",
        data: itemData.offline || [],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 400,
        stacked: true,
        stackType: "100%",
        toolbar: { show: false },
      },
      dataLabels: { enabled: true },
      colors: ["#cbaa33", "#309107"],
      plotOptions: { bar: { horizontal: true, columnWidth: "100%" } },
      title: { text: title, align: "left" },
      responsive: [
        {
          breakpoint: 400,
          options: {
            legend: {
              position: "bottom",
              offsetX: 0,
              offsetY: 0,
            },
          },
        },
      ],
      xaxis: {
        categories: itemData.days || [],
      },
    },
  };
  return (
    <Card
      style={{
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
      }}
      bodyStyle={{ padding: "15px 0px 0px 0px" }}
      loading={isLoading}
    >
      <ReactApexChart
        options={barChartData.options}
        series={barChartData.series}
        type="bar"
        height={300}
      />
    </Card>
  );
};

export default ChartData2;
