import React, { useState } from "react";
import image from "../image/slider2.jpg";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { BASE_URL } from "../utils";

export const Verification = () => {
  const { emailid } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    otp: "",
    email: emailid,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(!loading);
    try {
      await axios
        .post(`${BASE_URL}/auth/verify-otp`, formData)
        .then((response) => {
          if (response.data.success) {
            setLoading(!loading);
            Swal.fire({
              icon: "success",
              title: "success",
              text: response.data.message,
              bodydisable: false,
            }).then((result) => {
              navigate(`../login`);
            });
          } else {
            setLoading(!loading);
            Swal.fire({
              icon: "error",
              title: "Error",
              text: response?.data?.message,
            });
          }
        })
        .catch((error) => {
          setLoading(!loading);
          // Use SweetAlert to display an error message
          Swal.fire({
            icon: "error",
            title: "Error",
            text: error?.response?.data?.message,
          });
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to verify OTP. Please try again.",
      });
      return false;
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div
      className="row d-flex justify-content-center py-5"
      style={{ backgroundImage: `url(${image})` }}
    >
      <div className="col-lg-4">
        <form
          className="justify-content-center p-3 py-lg-4 w-100"
          onSubmit={handleSubmit}
        >
          <div className="d-flex flex-column bg-light px-2 py-5">
            <h4 className="mb-2 fs-2 text-center text-website">Verification</h4>
            <input
              type="text"
              name="otp"
              value={formData.otp}
              onChange={handleInputChange}
              className="form-control bg-light text-dark mt-3"
              placeholder="Please enter one time password"
              required
            />
            <button
              type="submit"
              className="btn btn-success mt-3"
              disabled={loading}
            >
              {loading ? "Loading..." : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
