import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";
import { AUTH_TOKEN, BASE_URL } from "../../../utils";

const FeeModel = ({
  show,
  handleClose,
  tokenid,
  getPatientList,
  editData = [],
  fees,
}) => {
  const [formFields, setFormFields] = useState([]);
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalFee, setTotalFee] = useState(fees);
  const [formData, setFormData] = useState({
    token_id: tokenid,
    regular_fee: fees || 0,
    discount: 0,
  });

  const fetchList = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${BASE_URL}/doctor/fees/list`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );

      if (response.status === 200) {
        setList(response.data.data);
        setIsLoading(false);
      } else {
        console.error("API request failed");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching the API:", error);
    }
  };

  useEffect(() => {
    fetchList();
    setFormFields(editData?.items || []);
  }, []);

  const handleSelectChange = (index, event) => {
    const newFields = [...formFields];
    const selectedFee = list.find((fee) => fee.title === event.target.value);
    newFields[index].value = selectedFee?.title || null;
    newFields[index].amount = selectedFee?.amount || 0;
    setFormFields(newFields);
    calculate(newFields, formData);
  };

  const getTotalPrice = (fields) => {
    return fields.reduce((total, invoice) => {
      return total + parseFloat(invoice.amount || 0);
    }, 0);
  };

  const calculate = (newFields, formData) => {
    let total = getTotalPrice(newFields);
    total =
      parseFloat(total) +
      parseFloat(formData.regular_fee) -
      parseFloat(formData.discount || 0);
    setTotalFee(total);
  };

  const handleInputChange = (index, event) => {
    const newFields = [...formFields];
    newFields[index].amount = event.target.value;
    setFormFields(newFields);
    calculate(newFields, formData);
  };

  const handleFormInputChange = (e) => {
    const { name, value } = e.target;
    const data = { ...formData, [name]: value };
    setFormData(data);
    calculate(formFields, data);
  };

  const handleAddField = () => {
    setFormFields([
      ...formFields,
      { id: "", amount: 0, type: "select", value: "" },
    ]);
  };

  const handleRemoveField = (index) => {
    const data = formFields.filter((_, i) => i !== index);
    setFormFields(data);
    calculate(data, formData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    var data = { ...formData, ["token_id"]: tokenid };
    data.fees = formFields.map((field) => ({
      title: field.value,
      amount: field.amount,
    }));

    try {
      const response = await axios.post(`${BASE_URL}/doctor/setting`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AUTH_TOKEN}`,
        },
      });

      if (response.status === 200) {
        setFormData({
          token_id: tokenid,
          regular_fee: "",
          discount: "",
        });
        setFormFields([]);
        setIsLoading(false);
        getPatientList();
        handleClose();
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      setIsLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Fee</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <div className="row mb-2">
            <div className="col-6">
              <label className="fs-6">Regular Fee</label>
            </div>
            <div className="col-4">
              <input
                className="form-control py-1"
                onChange={handleFormInputChange}
                name="regular_fee"
                type="number"
                size="sm"
                value={formData.regular_fee}
                onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
              />
            </div>
            <div className="col-1">
              {list.length > formFields.length && (
                <Button variant="info" size="sm" onClick={handleAddField}>
                  +
                </Button>
              )}
            </div>
          </div>

          {formFields.map((field, index) => (
            <div className="row mb-2" key={index}>
              <div className="col-6">
                <Form.Select
                  value={field.value}
                  onChange={(e) => handleSelectChange(index, e)}
                  size="sm"
                >
                  <option value="">Select Fee</option>
                  {list.map((option, optionIndex) => (
                    <option key={optionIndex} value={option.title}>
                      {option.title}
                    </option>
                  ))}
                </Form.Select>
              </div>
              <div className="col-4">
                <Form.Control
                  type="number"
                  value={field.amount}
                  onChange={(e) => handleInputChange(index, e)}
                  size="sm"
                  onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                />
              </div>
              <div className="col-1">
                <Button
                  variant="danger"
                  size="sm"
                  onClick={() => handleRemoveField(index)}
                >
                  X
                </Button>
              </div>
            </div>
          ))}

          <div className="row mb-4">
            <div className="col-6">
              <label className="mb-1">Discount</label>
            </div>
            <div className="col-6">
              <input
                className="form-control py-1"
                onChange={handleFormInputChange}
                name="discount"
                type="number"
                onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                value={formData.discount}
                size="sm"
              />
            </div>
          </div>
          <hr className="my-0 py-0" />
          <div className="row mb-4">
            <div className="col-6">
              <label className="mb-1 h4">Total</label>
            </div>
            <div className="col-4">- ₹{totalFee}</div>
          </div>

          <div className="text-end">
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <button
              disabled={isLoading}
              className="btn btn-primary ms-2"
              type="submit"
            >
              {isLoading && (
                <span className="spinner-border spinner-border-sm mr-1"></span>
              )}{" "}
              Submit
            </button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default FeeModel;
