import axios from "axios";
import { useState } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import { AUTH_TOKEN, BASE_URL } from "../utils";

export const PatientInModel = ({
  show,
  patient,
  setPatientInModel,
  allPatient,
  shift_id,
}) => {
  const [token_number, setTokenNumber] = useState(patient?.token_number);
  const [isLoading, setIsLoading] = useState(false);
  //patientIn
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await axios.post(
        `${BASE_URL}/doctor/patient/in`,
        {
          token_id: patient?.id,
          token_number: token_number,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );
      setPatientInModel(false);
      setIsLoading(true);
      allPatient(shift_id);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.response.data.message,
      });
      setIsLoading(false);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setTokenNumber(value);
  };

  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title className="h6">
          Patient In {patient.patient && <>- {patient.patient.name}</>}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit} className="py-3">
          <label style={{ fontSize: "14px" }}>Token Number</label>
          <Form.Control
            type="number"
            value={token_number}
            onChange={(e) => handleInputChange(e)}
            size="sm"
            name="token_number"
            placeholder=""
          />

          <div className="mt-3 text-end">
            <Button
              variant="secondary"
              onClick={() => setPatientInModel(false)}
            >
              Close
            </Button>
            <button
              disabled={isLoading}
              className="btn btn-primary ms-2"
              type="submit"
            >
              {isLoading && (
                <span className="spinner-border spinner-border-sm mr-1"></span>
              )}{" "}
              Submit
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};
