import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { login } from "../store/authSlice";
import image from "../image/slider2.jpg";

export const Login = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error } = useSelector((state) => state.auth);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(login(formData)).then((action) => {
      if (login.fulfilled.match(action) && action.payload) {
        navigate("/admin/analytical-reports");
        window.location.reload();
      }
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div
      className="row d-flex justify-content-center py-5"
      style={{ backgroundImage: `url(${image})` }}
    >
      <div className="col-lg-4">
        <form
          className="justify-content-center p-3 py-lg-4 w-100"
          onSubmit={handleSubmit}
        >
          <div className="d-flex flex-column bg-light px-2 py-5">
            <h4 className="mb-2 fs-2 text-center text-website">LogIn</h4>
            <input
              type="text"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className="form-control bg-light text-dark mt-3"
              placeholder="Username/Phone Number"
              required
            />
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              className="form-control bg-light text-dark mt-3"
              placeholder="******"
              required
            />
            <button
              type="submit"
              className="btn btn-success mt-3"
              disabled={loading}
            >
              {loading ? "Loading..." : "Submit"}
            </button>
            {error && <p className="text-danger mt-2">{error}</p>}
            <Link
              to="/ragister"
              className="btn btn-link mt-3 text-decoration-none"
            >
              <span className="text-black">Don't have an account?</span>{" "}
              Register
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};
