import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { Button, Modal } from "react-bootstrap";
export const ShiftModel = ({
  show,
  handleSubmit,
  handleInputChange,
  formData,
  modelToggle,
  isLoading,
  mode,
}) => {
  return (
    <div>
      <Modal className="modal-lg" show={show}>
        <Modal.Header>
          <Modal.Title className="h6">{mode} Shift</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-4 mb-3">
                <label className="mb-1">Shift Name</label>
                <input
                  className="form-control"
                  onChange={(e) => handleInputChange(e)}
                  name="name"
                  type="text"
                  value={formData.name}
                />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-4 mb-3">
                <label className="mb-1">Start Time</label>
                <input
                  className="form-control"
                  onChange={(e) => handleInputChange(e)}
                  name="from"
                  type="time"
                  value={formData.from}
                />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-4 mb-3">
                <label className="mb-1">End Time</label>
                <input
                  className="form-control"
                  onChange={(e) => handleInputChange(e)}
                  name="end_time"
                  type="time"
                  value={formData.end_time}
                />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-4 mb-3">
                <label className="mb-1">Consultation Time (in Min)</label>
                <input
                  className="form-control"
                  onChange={(e) => handleInputChange(e)}
                  name="avg_consult_time"
                  type="number"
                  value={formData.avg_consult_time}
                />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-4 mb-3">
                <label className="mb-1">Maximum Patient</label>
                <input
                  className="form-control"
                  placeholder="in number"
                  onChange={(e) => handleInputChange(e)}
                  name="max_patient"
                  type="number"
                  value={formData.max_patient}
                />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-4 mb-3">
                <label className="mb-1">Replace Token Amount</label>
                <input
                  className="form-control"
                  placeholder="in number"
                  onChange={(e) => handleInputChange(e)}
                  name="replace_amount"
                  type="number"
                  value={formData.replace_amount}
                />
              </div>
              <h6>Doctor Setup</h6>
              <hr />
              <div className="col-sm-12 col-md-12 col-lg-6 mb-3">
                <label className="mb-1">Hospital Name</label>
                <input
                  className="form-control"
                  onChange={(e) => handleInputChange(e)}
                  name="hospital_name"
                  type="text"
                  value={formData.hospital_name}
                />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6 mb-3">
                <label className="mb-1">Doctor Name</label>
                <input
                  className="form-control"
                  onChange={(e) => handleInputChange(e)}
                  name="doctor_name"
                  type="text"
                  value={formData.doctor_name}
                />
              </div>

              <div className="col-sm-12 col-md-12 col-lg-6 mb-3">
                <label className="mb-1">Doctor fees</label>
                <input
                  className="form-control"
                  onChange={(e) => handleInputChange(e)}
                  name="fees"
                  type="text"
                  value={formData.fees}
                />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6 mb-3">
                <label className="mb-1">Address</label>
                <input
                  className="form-control"
                  onChange={(e) => handleInputChange(e)}
                  name="address"
                  type="text"
                  value={formData.address}
                />
              </div>

              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                <label className="mb-1">Map Location Link</label>
                <input
                  className="form-control"
                  onChange={(e) => handleInputChange(e)}
                  name="map_location"
                  type="text"
                  value={formData.map_location}
                />
              </div>

              <h6>Online Booking Setup</h6>
              <hr />
              <div className="col-sm-12 col-md-12 col-lg-6 mb-3">
                <label className="mb-1">
                  Online booking start token number
                </label>
                <input
                  className="form-control"
                  onChange={(e) => handleInputChange(e)}
                  name="affter_patient"
                  type="number"
                  value={formData.affter_patient}
                />
              </div>

              <div className="col-sm-12 col-md-12 col-lg-6 mb-3">
                <label className="mb-1">Online Booking Status</label>
                <select
                  className="form-control"
                  onChange={(e) => handleInputChange(e)}
                  name="is_booking"
                  type="text"
                  value={formData.is_booking}
                >
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
              </div>

              <div className="col-sm-12 col-md-12 col-lg-6 mb-3">
                <label className="mb-1">Online booking End Time</label>
                <input
                  className="form-control"
                  onChange={(e) => handleInputChange(e)}
                  name="booking_time"
                  type="time"
                  value={formData.booking_time}
                />
              </div>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-end">
              <Button variant="secondary" onClick={modelToggle}>
                Close
              </Button>
              <button
                disabled={isLoading}
                className="btn btn-primary ms-2"
                type="submit"
              >
                {isLoading && (
                  <span className="spinner-border spinner-border-sm mr-1"></span>
                )}{" "}
                Submit
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
