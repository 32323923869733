import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";
import { AUTH_TOKEN, BASE_URL } from "../../../utils";
import Swal from "sweetalert2";

function AddMoneyModel({ show, modelToggle }) {
  const [amount, setAmount] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    axios
      .post(
        `${BASE_URL}/doctor/online-recharge`,
        { amount: amount },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      )
      .then((response) => {
        //console.log(response.data);
        if (response.data && response.data.data) {
          window.location.href = response.data.data;
        }
        return 0;
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
        setIsLoading(false);
      });
  };

  const handleInputChange = (e) => {
    setAmount(e.target.value);
  };

  return (
    <Modal show={show} onHide={modelToggle}>
      <Modal.Header closeButton>
        <Modal.Title>Add Amount to Wallet</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <div className="row mb-4">
            <div className="col-12">
              <label className="mb-1">Amount</label>
              <input
                className="form-control py-1"
                onChange={handleInputChange}
                name="amount"
                type="number"
                onKeyPress={(e) => {
                  if (!/[0-9]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                value={amount}
                placeholder="Enter Amount to be added"
              />
            </div>
          </div>
          <div className="text-end">
            <Button variant="secondary" onClick={modelToggle}>
              Close
            </Button>
            <button
              disabled={isLoading}
              className="btn btn-primary ms-2"
              type="submit"
            >
              {isLoading && (
                <span className="spinner-border spinner-border-sm mr-1"></span>
              )}{" "}
              Add Money
            </button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default AddMoneyModel;
