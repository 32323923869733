import React from "react";
import { Slider } from "../components/Slider";
import { Main } from "../components/Main";
import { Work } from "../components/Work";
import { Articles } from "../components/Articles";
import { DoctorAssociated } from "../components/DoctorAssociated";
import { Abouts } from "../components/Abouts";
import { Contact } from "../components/Contact";
import { Route, Routes } from "react-router-dom";
import { TokenDetail } from "../views/TokenDetail";
import Feedback from "../components/Feedback";
import { ReviewRating } from "../components/ReviewRating";
import { RefundPolicy } from "../components/RefundPolicy";
import { Terms } from "../components/Terms";
import PaymentResponse from "../components/PaymentResponse";
import DoctorProfile from "../components/DoctorProfile";
import { Privacy } from "../components/privacy";
// import { Privacy } from "../components/Privacy";

export const UserRoute = () => {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Slider />
              <DoctorAssociated />
              <Main />
              <Work />
              <Abouts />
              <ReviewRating />
              <Contact />
            </>
          }
        />
        <Route path="/slider" element={<Slider />} />
        <Route path="/main" element={<Main />} />
        <Route path="/work" element={<Work />} />
        <Route path="/articles" element={<Articles />} />
        <Route path="/doctorassociated" element={<DoctorAssociated />} />
        <Route path="/aboutus" element={<Abouts />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/tokenDetails" element={<TokenDetail />} />
        <Route path="/term-condition" element={<Terms />} />
        <Route
          path="/doctor-profile/:doctorName/:doctorId"
          element={<DoctorProfile />}
        />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
        <Route path="/feedback" element={<Feedback />} />
        <Route path="/error" element={<PaymentResponse />} />
      </Routes>
    </>
  );
};
