import React from "react";
import { BiEditAlt, BiUpArrowCircle } from "react-icons/bi";
export const Token = ({
  patient,
  recallModelToggle,
  type,
  editToken,
  tokenInModelToggle,
}) => {
  return (
    <div
      className="text-center text-white px-2 py-1 mb-2 me-1"
      style={{
        backgroundColor:
          patient.patient_in == 1
            ? patient.is_paid == ""
              ? "#bec719"
              : "#00a19a"
            : "#7bada8",
        borderRadius: "5px",
      }}
      role="button"
    >
      <span
        onClick={() => recallModelToggle(patient.token_number, type, patient)}
      >
        {patient.token_number} - {patient?.patient?.name || "No Name"}
      </span>
      <span
        onClick={() => editToken(patient.id)}
        className="ms-2 text-warning"
        style={{ borderLeft: "1px solid white" }}
        title="Edit Patient"
        role="button"
      >
        {" "}
        <BiEditAlt style={{ color: "#ff0000" }} />
      </span>
      {patient.patient_in != 1 && patient.patient && (
        <span
          onClick={() => tokenInModelToggle(patient)}
          className="ms-2"
          style={{ borderLeft: "1px solid white" }}
          title="Patient In"
          role="button"
        >
          {" "}
          <BiUpArrowCircle style={{ color: "yellow" }} />
        </span>
      )}
    </div>
  );
};
